import React from "react";

const ServiceContent = [
  {
    icon: "ti-desktop",
    no: "01",
    service: "Software Engineering",
    title: "Web Apps and Application Development",
    desc: `I have over 10 years of experience developing sofware/web applications in C# and JavaScript. 
           I am also very well experienced with modern frameworks such as .NET Core and React & Redux. 
           I have made use of these tools when building enterprise level microservices for various small to large organisations`,
  },
  {
    icon: "ti-loop",
    no: "02",
    service: "CI/CD Pipelines",
    title: "Continuous Integration and Continuous Deployment",
    desc: `Developing a reliable way to track code, and shop code is extremely important when building any application whether small or large. 
           There are several tools out there that does the job and I have experience with the most common ones such as
            TeamCity, TravisCI, Gitlab, Azure Pipelines and many more.`,
  },
  {
    icon: "ti-server",
    no: "03",
    service: "Infrastructure Development",
    title: "Scalable infrastructure development",
    desc: `These days, most applications are now running on cloud environments such as AWS, Azure and GCP.
           I have helped build infrastructures with all 3 major cloud providers while taking scalability and security
           into serious considering. I am also well experienced with IaaS tools like Terraform which quick deployment and replication of the infrastructure`,
  }
];

const ServiceTwo = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div className="col-sm-6 col-lg-4" key={i}>
            <div className="feature-box-01">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              <h5>{val.title}</h5>
              <p>{val.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwo;
