import axios from "axios"

class Axios {

    async axiosGet(url) {
        const response = await axios.get(url)
            .then(res => res)
            .catch(err => console.log("Error occured", err));

        return response;
    }

    async axiosPost(url, data) {
        const response = axios.post(url, data)
            .then(res => res)
            .catch(err => console.log("Error occured", err))

        return response;
    }
}

export default Axios;