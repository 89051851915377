import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Axios from "../../helpers/axios";
import ReactGA from "react-ga4";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let [sentEmail, setSentEmail] = useState();

  const onSubmit = (data, e) => {
    e.target.reset();

    let baseEndpoint = ""
    if(process.env.NODE_ENV === "development"){
      baseEndpoint = "http://localhost:5000/api/email";
    }else{
      baseEndpoint = window?.apiUrls?.FLOORMIND_EMAIL_API;
    }

    const axiosObj = new Axios();

    axiosObj.axiosPost(baseEndpoint, data)
        .then(response => {
          setSentEmail(response.data);
    })

    ReactGA.event({
      category: "Submit",
      action: `Contact submit clicked`,
      label: "Email sent", // optional
      nonInteraction: false, // optional, true/false
    })
  };

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "contact"
  });

  return (
    <>
      <form className="contct-form" onSubmit={handleSubmit(onSubmit)}>
        {sentEmail === true &&
          <span className="valid-feedback">Email Sent</span>
        }
        {sentEmail === false &&
        <span className="invalid-feedback">There is an issue sending your email, we have logged this, please try again later</span>
        }
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register("name", { required: true })}
              />
              <label className="form-label">Name</label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                {...register(
                  "emailFrom",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              <label className="form-label">Subject</label>
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                rows="4"
                className="form-control"
                placeholder="Type comment"
                {...register("body", { required: true })}
              ></textarea>
              <label className="form-label">Comment</label>
              {errors.comment && (
                <span className="invalid-feedback">Comment is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-theme">Send your message</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
