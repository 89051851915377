import React from 'react'
import SkillsItem from "./SkillsItem";

const SkillsBoxGrey = (props) => {
    let count = 0;
    return (
        <>
            <div className="col-lg-3 my-3">
                <div className="sm-title">
                    <h4 className="font-alt">{props.title}</h4>
                </div>
                <div className="gray-bg skill-box">
                    {props.skillItems.map((skill) => <SkillsItem key={count++} title={skill.title} level={skill.level}/>)}
                    {/* End skill-lt */}
                </div>
            </div>
        </>
    )
}

export default SkillsBoxGrey;