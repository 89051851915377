import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import Axios from "../../helpers/axios";
import Gallery from "react-photo-gallery";
import Carousel, {ModalGateway, Modal}from "react-images";
import ReactGA from "react-ga4";

const Portfolio = () => {
    let baseEndpoint = ""

    if (process.env.NODE_ENV === "development") {
        baseEndpoint = process.env.REACT_APP_LOCAL_FLOORMIND_PORFOLIO_API;
    } else {
        baseEndpoint = window?.apiUrls?.FLOORMIND_PORTFOLIO_API;
    }

    //Lightbox
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const openLightbox = useCallback((event, {photo, index}) => {

                ReactGA.event({
                    category: "Lightbox open",
                    action: `Photo lightbox opened`,
                    label: "Lightbox", // optional
                    nonInteraction: false, // optional, true/false
                })
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
            ReactGA.event({
                category: "Lightbox closed",
                action: `Photo lightbox closed`,
                label: "Lightbox", // optional
                nonInteraction: false, // optional, true/false
            })
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const {category, desc} = useParams();
    const imgSrc = `${baseEndpoint}/${category}/${desc}`;
    const [fetchedItems, setFetchedItems] = useState([])
    const imageList = []
    useEffect(() => {

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: `${category}/${desc}`
        });

        const axiosObj = new Axios()
        axiosObj.axiosGet(imgSrc)
            .then(response => {
                console.log(response);
                // eslint-disable-next-line array-callback-return
                response.data.map(photo => {
                    imageList.push({
                        "src": photo,
                        "width": 5,
                        "height": 7
                    })
                })
                setFetchedItems(imageList);
            }).catch((error) => {
            if (error.response) {
                console.log(error.response.data);
            }
        })
    },[]);



    return (
        <>
            <Typography className={"divPortfolioCategoryTitle"} variant="h1" component="div" gutterBottom>
                {desc}
            </Typography>
            <div className={"divPortfolioImageList"}>
                {fetchedItems != null &&
                    <Gallery photos={fetchedItems} onClick={openLightbox}/>
                }
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={fetchedItems.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </>
    )
};

export default Portfolio;