import React, {useState} from "react";
import ReactGA from "react-ga4";

const About = () => {
  const [click, setClick] = useState(false)

  const handleClick = (value) => {
    ReactGA.event({
      category: "Submit",
      action: `Download submit clicked`,
      label: "CV Downloaded", // optional
      nonInteraction: false, // optional, true/false
    })
    setClick(!click)
  }

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "about"
    });

  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box dark-img-box">
            <img src="img/about/floormind-about-1.jpg" alt="floormind in suit" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>Ife Ayelabola Jr</h3>
            <h5>
              <span className="color-theme">Multi-Disciplinary Engineer</span>
            </h5>
            <p>
              I am an enthusiastic <span className="color-theme">engineer</span> with a diverse skill set, focusing on creating scalable enterprise applications. 
              My expertise spans the entire development cycle, from architectural design and application development to final delivery. 
              I am dedicated to selecting the most suitable technologies for each task. 
              My extensive background includes constructing <span className="color-theme">microservices</span> using languages such as <span className="color-theme">C#, Java, Python, React, and Redux.</span>
              I am also proficient in <span className="color-theme">containerization</span> using <span className="color-theme">Docker</span> and adept at establishing <span className="color-theme">Kubernetes</span> clusters for their deployment, employing infrastructure-as-code tools like <span className="color-theme">Terraform</span>.
              My commitment extends to implementing <span className="color-theme">DevSecOps</span> practices, ensuring stringent security measures across the <span className="color-theme">CI/CD</span> process, and fortifying Kubernetes clusters with comprehensive <span className="color-theme">cybersecurity protocols</span>.
            </p>
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="docs/Ife-resume.pdf" download onClick={() => handleClick("Download CV Clicked")}>
                Download CV
              </a>
            </div>
            <hr/>
            <h5>
              <span className="color-theme"> Creative Photographer / Story Teller</span>
            </h5>
            <p>
              I am a dedicated <span className="color-theme">photographer</span> and <span className="color-theme">visual storyteller</span>, passionate about capturing moments that tell compelling narratives through the lens. 
              My artistic journey revolves around the fusion of photography and storytelling, where each image becomes a piece of a larger, evocative story. 
              With a keen eye for detail and a deep appreciation for aesthetics, I specialize in <span className="color-theme">portrait photography</span>, skillfully bringing out the essence and emotions of my subjects. 
              Additionally, my love for the outdoors finds expression in my landscape photography, as I seek to encapsulate the grandeur and serenity of natural environments. 
              The bustling energy of <span className="color-theme">street photography</span> also resonates with me, allowing me to document the candid beauty of everyday life. 
              Collaborating with other creative minds, such as set <span className="color-theme">designers</span> and <span className="color-theme">stylists</span>, is where my passion truly flourishes. 
              Together, we transform concepts into captivating realities, crafting visual narratives that resonate on a profound level.
            </p>
            <a className="px-btn px-btn-theme btn-md" href="#photography">
              Photography
            </a>
            <hr/>
            <h5>
              Contact
            </h5>
            <div className="row about-list">
              {/* End .col */}
              <div className="col-md-6">
                <div className="media">
                  <label>Location</label>
                  <p>United Kingdom</p>
                </div>
                <div className="media">
                  <label>E-mail</label>
                  <p>info@floormind.com</p>
                </div>
                <div className="media">
                  <label>Instagram</label>
                  <p>@nomadcoder</p>
                </div>
                <div className="media">
                  <label>Twitter</label>
                  <p>@floormind</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
