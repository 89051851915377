import {React} from "react";
import Header from "../../components/header/HeaderFluid";
import Slider from "../../components/slider/SliderTwo";
import About from "../../components/about/AboutFour";
import Service from "../../components/service/ServiceTwo";
import Skills from "../../components/skills/Skills";
import Photography from "../../components/photography/Photography";
import Blog from "../../components/blog/Blog";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function HomeTwo() {
    useDocumentTitle("Floormind || Ife Ayelabola's portfolio website");

    return (
        <div className="home-dark">
            <Header/>
            {/* End Header Section */}
            <Slider/>
            {/* End Banner Section */}

            {/* About Me */}
            <section id="about" className="section about-section after-left-section">
                <div className="container">
                    <About/>
                </div>
            </section>
            {/* End About Me */}

            {/* Services */}
            <section id="engineering" className="section services-section gray-bg">
                <div className="container">
                    <div className="row justify-content-center section-title text-center">
                        <div className="col-lg-7">
                            <h3 className="font-alt">Engineering</h3>
                            <p>
                                As a full stack software engineer, I am extremely hands-on on every part of Software
                                development.
                                From the Infrastructure, to Backend Api development and front end.
                            </p>
                        </div>
                    </div>
                    {/* End .row */}
                    <Service/>
                </div>
            </section>
            {/* End Services */}

            {/*  Skills */}
            <section className="section skill-section">
                <div className="container">
                    <div className="row justify-content-center section-title text-center">
                        <h3 className="font-alt">Technical Skills</h3>
                    </div>
                    <Skills/>
                </div>
            </section>
            {/* End Skills */}

            <section id="photography" className="section gray-bg">
                <div className="container">
                    <div className="row justify-content-center section-title text-center">
                        <div className="col-lg-7">
                            <h3 className="font-alt">Photography</h3>
                            <p>
                                I curate photography sessions, bringing ideas to life.
                                From creating mood boards with clients to set design and organising the photography
                                sessions.
                            </p>
                        </div>
                    </div>
                    {/* End .row */}
                    <Photography/>
                </div>
            </section>
            {/* End Portfolio */}

            {/*      <div className="section testimonial">
        <div className="container">
          <Testimonial />
        </div>
      </div>*/}
            {/* End testimonial */}

            {/* Blog */}
            <section id="blog" className="section">
              <div className="container">
                <div className="row justify-content-center section-title text-center">
                  <div className="col-lg-7">
                    <h3 className="font-alt">Recent articles</h3>
                  </div>
                </div>
                {/* End .row */}
                <Blog />
              </div>
            </section>
            {/* End Blog */}

            {/* Contact */}
            <section id="contact" className="section after-left-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 my-3">
                            <div className="contct-form">
                                <div className="sm-title">
                                    <h4 className="font-alt">Contact us</h4>
                                </div>
                                {/* End .sm-title */}
                                <Contact/>
                            </div>
                        </div>
                        {/* End .col */}

                        <div className="col-lg-4 ml-auto my-3">
                            <div className="contact-info">
                                <div className="sm-title">
                                    <h4 className="font-alt">Get in touch</h4>
                                    <p>
                                        Please get intouch for some freelance work.
                                    </p>
                                </div>
                                <div className="media">
                                    <div className="icon">
                                        <i className="ti-email"></i>
                                    </div>
                                    <span className="media-body">
                    info@floormind.com
                  </span>
                                </div>
                                {/* End media */}

                            </div>
                        </div>
                        {/* End .col */}
                    </div>
                </div>
            </section>
            {/* End Contact */}

            {/* Footer */}
            <footer className="footer white">
                <div className="container">
                    <Footer/>
                </div>
            </footer>
            {/*  End Footer */}
        </div>
    );
};

//export default HomeTwo;
