import React from 'react'

const SkillsItem = (props) => {
    return (
        <>
            <div className="skill-lt">
                <h6>{props.title}</h6>
                <div className="skill-bar">
                    <div
                        className="skill-bar-in theme-bg"
                        style={{ width: props.level + "%" }}
                    >
                        <span>{props.level}%</span>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SkillsItem;