import React from "react";
import SkillsBoxGrey from "./SkillsBoxGrey";

const frontEnd = [
    {title: "HTML & CSS", level: 90},
    {title: "Bootstrap", level: 90},
    {title: "ES6", level: 65},
    {title: "React & Redux", level: 80},
    {title: "Angular", level: 30},
    {title: "VueJS", level: 20}
]

const backEnd = [
    {title: "C# & .NET CORE", level: 95},
    {title: "Java", level: 80},
    {title: "Python", level: 50},
    {title: "Kafka", level: 70},
    {title: "Elastic Stack", level: 70},
    {title: "GoLang", level: 40},
]

const cicd = [
    {title: "Gitlab", level: 90},
    {title: "TravisCI", level: 90},
    {title: "TeamCity", level: 80},
    {title: "OctopusDeploy", level: 80},
    {title: "Azure Pipelines", level: 60},
    {title: "Jenkins", level: 30}
]

const orchestration = [
    {title: "Docker", level: 90},
    {title: "Kubernetes", level: 90},
    {title: "Terraform", level: 80},
    {title: "GCP", level: 70},
    {title: "AWS", level: 80},
    {title: "Azure", level: 70}
]

const Skills = () => {
    return (
        <>
            <div className="row">
                <SkillsBoxGrey title="Frontend" skillItems={frontEnd}/>
                <SkillsBoxGrey title="Backend" skillItems={backEnd}/>
                <SkillsBoxGrey title="CI/CD" skillItems={cicd}/>
                <SkillsBoxGrey title="Orchestration" skillItems={orchestration}/>
                {/* End .col */}

            </div>
        </>
    );
};

export default Skills;
