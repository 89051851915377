import React, {useState} from 'react'
import ReactGA from "react-ga4";

const PhotographyTabItem = (props) => {
    const [click, setClick] = useState(false);
    const handleClick = (val) => {
            ReactGA.event({
                category: "Nav",
                action: `${val} Photography Clicked`,
                label: "Portfolio Viewing", // optional
                nonInteraction: false, // optional, true/false
            });
        setClick(!click);
    }

    return (
        <>
            <div className="portfolio-content row lightbox-gallery">
                {props.tabItems.map((val, i) => (
                    <div
                        className="col-sm-6 col-lg-4 grid-item product"
                        key={i}
                    >
                        <div className="portfolio-box-01">
                            <div className="portfolio-img">
                                <div className="portfolio-info">
                                    <h5>
                                        <a href={val.portfolioLink} target="_blank" rel="noreferrer">
                                            {val.title}
                                        </a>
                                    </h5>
                                    <span>{val.subTitle}</span>
                                </div>
                                {/* End .portfolio-info */}
                                <a
                                    href={val.portfolioBannerLink}
                                    className="gallery-link"
                                >
                                    <img
                                        src={val.portfolioBannerLink}
                                        alt={val.alterText}
                                    />
                                </a>
                                {/* End gallery link */}
                                <a
                                    href={val.portfolioLink}
                                    className="portfolio-icon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {handleClick(val.title)}}
                                >
                                    <span className="ti-link"></span>
                                </a>
                                {/* End .portfolio-icon */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* End list wrapper */}
        </>
    )
}

export default PhotographyTabItem;