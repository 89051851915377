import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import Axios from "../../helpers/axios";
import ReactGA from "react-ga4";

Modal.setAppElement("#root");

const Blog = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [returnedData, setReturnedData] = useState();
    const [click, setClick] = useState(false)
    const axiosObj = new Axios();
    useEffect(() => {
        axiosObj.axiosGet(`https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40floormind`)
            .then(res => {
                setReturnedData(res.data);
                setLoading(false);
            }).catch((error) => {
            if(error.response) {
                console.log(error.response.data)
            }
        })
    });

    const handleClick = (val) => {
            ReactGA.event({
                category: "Nav",
                action: `${val} Blog Clicked`,
                label: "Navigated to blog", // optional
                nonInteraction: false, // optional, true/false
            });
        setClick(!click);
    }

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "blog"
        });

    return (
        <>
            <div className="row">
                {returnedData.items.map((val, i) => (
                    <div className="col-lg-4 col-md-6 my-3">
                        <div className="blog-post">
                            <div className="blog-img">
                                <div className="data">
                                    <span></span>
                                    <small>Jan</small>
                                </div>
                                <img src={val.thumbnail} style={{width: "100%", height: "100%"}} alt="blog post"></img>
                            </div>
                            {/* End blog-img */}

                            <div className="blog-info">
                                <h6>{val.title}</h6>
                                <p>
                                    {val.title}
                                </p>
                                <div className="btn-bar">
                                    <a href={val.link} onClick={() => handleClick(val.title)}>
                                        Read More
                                    </a> </div>
                            </div>
                            {/* End blog-info */}
                        </div>
                    </div>
                ))}
                {/* End .col for blog-1 */}
            </div>
            {/* End .row */}

        </>
    );
};

export default Blog;
