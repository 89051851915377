import React from "react";
import Routes from "./router/Routes";
import "aos/dist/aos.css";
import HomeTwo from "./views/all-home-version/HomeDark";
import ReactGA from "react-ga4";

const App = () => {
    if(process.env.NODE_ENV !== "development") {
        ReactGA.initialize(window?.apiUrls?.GA_MEASUREMENT_ID)
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "home page"
        });
    }
  return (
    <>
        <Routes>
            <HomeTwo />
        </Routes>
    </>
  );
};

export default App;
